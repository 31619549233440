import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from '../context/DataContext';
import "../css/impressum.css";


function Impressum() {
  const { data, fetchData } = useContext(DataContext);
  const [activeIndex, setActiveIndex] = useState(null); // Zustand, um den aktuell erweiterten Abschnitt zu verfolgen

  useEffect(() => {
    fetchData('Impressum');
  }, [fetchData]);
  
  const impressumData = data['Impressum'] || [];

  const datenschutzUnterpunkte = impressumData
    .filter(item => item.pid === 6)
    .sort((a, b) => a.sortid - b.sortid);

  const toggleContent = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Aktualisiert den Zustand basierend auf dem Klick
  };

  return (
    <div className="section-8 wf-section">
      <div className="div-block-49">
        <h1 className="heading-13">Impressum</h1>
        <div className="text-block-107" dangerouslySetInnerHTML={{ __html: impressumData.find(item => item.text === 'impressum-intro')?.inhalt }} />
      </div>

      <h1 className="heading-14">Datenschutzerklärung</h1>
      <div className="f-accordian-wrapper-copy">
        {datenschutzUnterpunkte.map((unterpunkt, index) => (
          <div key={index} className="f-accordian-dropdown w-dropdown">
            <div className="f-accordian-toggle w-dropdown-toggle" onClick={() => toggleContent(index)}>
              <div className="f-accordian-title-wrapper">
                <div className="f-accordian-title">{unterpunkt.text}</div>
                <div className="f-accordian-icon w-embed">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </div>
              </div>
            </div>
            {activeIndex === index && (
              <nav className="f-accordian-list w-dropdown-list">
                <div className="f-accordian-content">
                  <p className="f-paragraph-small" dangerouslySetInnerHTML={{ __html: unterpunkt.inhalt }} />
                </div>
              </nav>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Impressum;



import React, { useState } from "react";
import "../css/navbar.css";
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenuOnMobile = () => {
    if (window.innerWidth <= 1390) {
      setShowMenu(false);
    }
  };

  return (
      <nav className="nav">
      <a href="/">
          <img src="/images/img-66-332x249.png" alt="Logo" />
        </a>

        <div className={`nav__menu ${showMenu ? "show-menu" : ""}`} id="nav-menu">
          <ul className="nav__list">
            <div className="nav__item">
            <Link to="/header" className="nav__link" onClick={closeMenuOnMobile}>
              Dienstleistungen
              </Link>
            </div>
            <div className="nav__item">
              <Link to="/team" className="nav__link" onClick={closeMenuOnMobile}>
                Mitarbeiter
                </Link>
                </div> 
                <div className="nav__item">
              <Link to="/contact" className="nav__link" onClick={closeMenuOnMobile}>
                Kontakt
                </Link>
                </div> 
                <div className="nav__item">
                  <Link to="/impressum" className="nav__link" onClick={closeMenuOnMobile}>
                    Impressum
                    </Link>
                    </div>
            <div className="nav__item">            
            <div className="nav__cta">
              <a href="mailto:iho@sijelly.it" className="nav__cta-link">Email: iho@sijelly.it</a>
              <a href="tel:015779200456" className="nav__cta-link">Tel: 01577-9200456</a>
              </div>
              </div>
              <div className="nav__item">
              <a href="https://www.google.de/maps/place/Löningstraße+35,+28195+Bremen/@53.0796964,8.8135418,17z/data=!3m1!4b1!4m6!3m5!1s0x47b1280e72b49953:0xdab80a1b3a57e058!8m2!3d53.0796936!4d8.8158226!16s%2Fg%2F11c5jds879?entry=ttu" className=" nav__cta">
              Löningstr. 35<br />28195 Bremen
              </a>
              </div>
              </ul>
              <button className="nav__close" id="nav-close" onClick={toggleMenu}>
               &#x2715; {/* Emoji für das Schließen-Symbol (rotes X) */}
               </button>
               </div>
               <button className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
                &#x2630; {/* Emoji für das Menü-Symbol (Hamburger Icon) */}
                </button>
                </nav>
  );
};

export default Navbar;
import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from './context/DataContext';
import { Navigate } from 'react-router-dom';

function InitialLoad() {
  const { fetchData, fetchBildUrl } = useContext(DataContext);
  const [loadingComplete, setLoadingComplete] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      // Liste aller erforderlichen fetchData und fetchBildUrl Aufrufe
      await Promise.all([
        fetchData('Header'),
        fetchBildUrl('background-image'),
        // Weitere fetchData oder fetchBildUrl Aufrufe
      ]);

      setLoadingComplete(true); // Setzen Sie loadingComplete auf true, sobald alle Promises erfüllt sind
    };

    loadData();
  }, [fetchData, fetchBildUrl]);

  if (loadingComplete) {
    return <Navigate to="/header" />; // Weiterleitung zur /header Seite, wenn das Laden abgeschlossen ist
  }

  return <div>Lädt...</div>; // Anzeigen eines Ladehinweises, während Daten geladen werden
}

export default InitialLoad;
import React, { useContext, useEffect } from 'react';
import { DataContext } from '../context/DataContext';
import "../css/header.css";

function Header() {
  const { data, fetchData, fetchBildUrl, bilderUrls } = useContext(DataContext);

  useEffect(() => {
    fetchData('Header');
    fetchBildUrl('background-image');
  }, [fetchData, fetchBildUrl]); // Entferne bilderUrls aus den Abhängigkeiten

  const headerData = data['Header'] || [];
  const findText = (textKey) => headerData.find(item => item.text === textKey)?.inhalt || '...';
  const stichpunkte = headerData.filter(item => item.text.startsWith('stichpunkt'));
  const backgroundImageUrl = bilderUrls['background-image'];


  return (
    <section className="section-2 wf-section">
      <div className="lynx-content-hero">
        <div className="lynx-hero-large">
          {backgroundImageUrl && (
            <img src={backgroundImageUrl} alt="Hintergrund" className="lynx-background-image" />
          )}
          <div className="lynx-block-hero-large">
            <h1 className="lynx-heading-white">{findText('Begrüßung')}</h1>
            <p className="lynx-paragraph-white">{findText('paragraph')}</p>
            {stichpunkte.length > 0 && (
              <ul className="list">
                {stichpunkte.map((stichpunkt, index) => (
                  <li key={index} className="text-block">{stichpunkt.inhalt}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Header;



import React, { createContext, useState } from 'react';
import API_URL from './config';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [data, setData] = useState({});
  const [bilderUrls, setBilderUrls] = useState({});
  const [geladeneBilder, setGeladeneBilder] = useState({});
  // Entfernen von geladeneBereiche, da es nicht mehr benötigt wird

  const fetchData = async (bereich) => {
    // Prüfe, ob der Bereich bereits geladen wurde, um mehrfaches Laden zu vermeiden
    if (data[bereich]) {
      console.log(`Daten für Bereich ${bereich} wurden bereits geladen.`);
      return;
    }

    try {
      const response = await fetch(`${API_URL}/texte?bereich=${bereich}`);
      const newData = await response.json();

      // Aktualisiere den data Zustand mit den neuen Daten, organisiert nach Bereichen
      setData(prevData => ({ ...prevData, [bereich]: newData }));
    } catch (error) {
      console.error('Fehler beim Abrufen der Daten:', error);
    }
  };

  const fetchBildUrl = async (imageKey) => {
    if (geladeneBilder[imageKey]) {
      console.log(`Bild ${imageKey} wurde bereits geladen.`);
      return;
    }

    try {
      const url = `${API_URL}/bilder/${imageKey}`;
      console.log("Abruf der Bild-URL:", url);
      const response = await fetch(url);
      if (!response.ok) throw new Error('Netzwerkantwort war nicht ok');

      const blob = await response.blob();
      const imageObjectURL = URL.createObjectURL(blob);

      setBilderUrls(prevUrls => ({ ...prevUrls, [imageKey]: imageObjectURL }));
      setGeladeneBilder(prevGeladene => ({ ...prevGeladene, [imageKey]: true }));
    } catch (error) {
      console.error('Fehler beim Abrufen des Bild-URLs:', error);
    }
  };

  return (
    <DataContext.Provider value={{ data, bilderUrls, fetchData, fetchBildUrl }}>
      {children}
    </DataContext.Provider>
  );
};




